import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Disqus } from 'gatsby-plugin-disqus'

//import NavBar from "../components/navbar"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactsPage = () => {
	let disqusConfig = {
		url: "https://olymptrade.belobot.ru/contacts",
		identifier: "Contacts",
		title: "contacts",
	}
	
	return(
	<Layout>
		<Seo title="Contacts"/>
		<div className="container pt-4">
			<div className="row">
				<div className="col">
					<h3>Contacts</h3>
					<p>If you have questions or problems with Olymp Trade Robot, please contact us:</p>
					<p>When asking a question, indicate your UID on the OlympTrade platform!</p>
					<p  className="fw-bold">trader.vitaly@gmail.com</p>
					<p>Before submitting a question, please check, it may already be answered in the <Link to="/faq">FAQ section</Link>.</p>
					<p>To get an answer to your question faster, you can leave it in the comments to the video on our <a href="https://www.youtube.com/channel/UClpv2djy_xmCtgQ4WOhz6vg">YouTube channel</a>.<br/>
					You can also use the feedback form</p>
					<Disqus config={disqusConfig} />
				</div>				
			</div>
		</div>
		
		
	</Layout>
	)
}

export default ContactsPage